import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@layouts/default';
import SEO from '@components/meta/SEO';
import { graphql } from 'gatsby';
import PageHeader from '@components/common/PageHeader';
import SimpleTextSection from '@components/common/SimpleTextSection';

const ThankYouPage = ({ data }) => (
  <Layout>
    <SEO title="Contact" />
    <PageHeader
      pageTitle="CONTACT US"
      image={data.headerImage.childImageSharp.fluid}
    />
    <SimpleTextSection
      heading="THANK YOU"
      text="Your message has been successfully submitted."
    />
  </Layout>
);

ThankYouPage.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line
};

export default ThankYouPage;

export const pageQuery = graphql`
  query {
    headerImage: file(relativePath: {eq: "page-header-contact.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
